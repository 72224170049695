import React from 'react';
import { FixSelectFormItem } from '../../../common/form/inputFormItems/FixSelectFormItem';

export function BachelorDept(props: { propertyName: any, onChange ?: any }) {
    return (
      <FixSelectFormItem
        wrapperCol={{ span: 16 }}
        labelCol={{ span: 8 }}
        onChange={props.onChange}
        propertyName={props.propertyName}
        showSearch={true}
        options={[
            { value: '01 : Ain', label: '01 : Ain' },
            { value: '02 : Aisne', label: '02 : Aisne' },
            { value: '03 : Allier', label: '03 : Allier' },
            { value: '04 : Alpes-de-Haute-Provence', label: '04 : Alpes-de-Haute-Provence' },
            { value: '05 : Hautes-Alpes', label: '05 : Hautes-Alpes' },
            { value: '06 : Alpes-Maritimes', label: '06 : Alpes-Maritimes' },
            { value: '07 : Ardèche', label: '07 : Ardèche' },
            { value: '08 : Ardennes', label: '08 : Ardennes' },
            { value: '09 : Ariège', label: '09 : Ariège' },
            { value: '10 : Aube', label: '10 : Aube' },
            { value: '11 : Aude', label: '11 : Aude' },
            { value: '12 : Aveyron', label: '12 : Aveyron' },
            { value: '13 : Bouches-du-Rhône', label: '13 : Bouches-du-Rhône' },
            { value: '14 : Calvados', label: '14 : Calvados' },
            { value: '15 : Cantal', label: '15 : Cantal' },
            { value: '16 : Charente', label: '16 : Charente' },
            { value: '17 : Charente-Maritime', label: '17 : Charente-Maritime' },
            { value: '18 : Cher', label: '18 : Cher' },
            { value: '19 : Corrèze', label: '19 : Corrèze' },
            { value: '2A : Corse-du-Sud', label: '2A : Corse-du-Sud' },
            { value: '2B : Haute-Corse', label: '2B : Haute-Corse' },
            { value: '21 : Côte-d\'Or', label: '21 : Côte-d\'Or' },
            { value: '22 : Côtes-d\'Armor', label: '22 : Côtes-d\'Armor' },
            { value: '23 : Creuse', label: '23 : Creuse' },
            { value: '24 : Dordogne', label: '24 : Dordogne' },
            { value: '25 : Doubs', label: '25 : Doubs' },
            { value: '26 : Drôme', label: '26 : Drôme' },
            { value: '27 : Eure', label: '27 : Eure' },
            { value: '28 : Eure-et-Loir', label: '28 : Eure-et-Loir' },
            { value: '29 : Finistère', label: '29 : Finistère' },
            { value: '30 : Gard', label: '30 : Gard' },
            { value: '31 : Haute-Garonne', label: '31 : Haute-Garonne' },
            { value: '32 : Gers', label: '32 : Gers' },
            { value: '33 : Gironde', label: '33 : Gironde' },
            { value: '34 : Hérault', label: '34 : Hérault' },
            { value: '35 : Ille-et-Vilaine', label: '35 : Ille-et-Vilaine' },
            { value: '36 : Indre', label: '36 : Indre' },
            { value: '37 : Indre-et-Loire', label: '37 : Indre-et-Loire' },
            { value: '38 : Isère', label: '38 : Isère' },
            { value: '39 : Jura', label: '39 : Jura' },
            { value: '40 : Landes', label: '40 : Landes' },
            { value: '41 : Loir-et-Cher', label: '41 : Loir-et-Cher' },
            { value: '42 : Loire', label: '42 : Loire' },
            { value: '43 : Haute-Loire', label: '43 : Haute-Loire' },
            { value: '44 : Loire-Atlantique', label: '44 : Loire-Atlantique' },
            { value: '45 : Loiret', label: '45 : Loiret' },
            { value: '46 : Lot', label: '46 : Lot' },
            { value: '47 : Lot-et-Garonne', label: '47 : Lot-et-Garonne' },
            { value: '48 : Lozère', label: '48 : Lozère' },
            { value: '49 : Maine-et-Loire', label: '49 : Maine-et-Loire' },
            { value: '50 : Manche', label: '50 : Manche' },
            { value: '51 : Marne', label: '51 : Marne' },
            { value: '52 : Haute-Marne', label: '52 : Haute-Marne' },
            { value: '53 : Mayenne', label: '53 : Mayenne' },
            { value: '54 : Meurthe-et-Moselle', label: '54 : Meurthe-et-Moselle' },
            { value: '55 : Meuse', label: '55 : Meuse' },
            { value: '56 : Morbihan', label: '56 : Morbihan' },
            { value: '57 : Moselle', label: '57 : Moselle' },
            { value: '58 : Nièvre', label: '58 : Nièvre' },
            { value: '59 : Nord', label: '59 : Nord' },
            { value: '60 : Oise', label: '60 : Oise' },
            { value: '61 : Orne', label: '61 : Orne' },
            { value: '62 : Pas-de-Calais', label: '62 : Pas-de-Calais' },
            { value: '63 : Puy-de-Dôme', label: '63 : Puy-de-Dôme' },
            { value: '64 : Pyrénées-Atlantiques', label: '64 : Pyrénées-Atlantiques' },
            { value: '65 : Hautes-Pyrénées', label: '65 : Hautes-Pyrénées' },
            { value: '66 : Pyrénées-Orientales', label: '66 : Pyrénées-Orientales' },
            { value: '67 : Bas-Rhin', label: '67 : Bas-Rhin' },
            { value: '68 : Haut-Rhin', label: '68 : Haut-Rhin' },
            { value: '69 : Rhône', label: '69 : Rhône' },
            { value: '70 : Haute-Saône', label: '70 : Haute-Saône' },
            { value: '71 : Saône-et-Loire', label: '71 : Saône-et-Loire' },
            { value: '72 : Sarthe', label: '72 : Sarthe' },
            { value: '73 : Savoie', label: '73 : Savoie' },
            { value: '74 : Haute-Savoie', label: '74 : Haute-Savoie' },
            { value: '75 : Paris', label: '75 : Paris' },
            { value: '76 : Seine-Maritime', label: '76 : Seine-Maritime' },
            { value: '77 : Seine-et-Marne', label: '77 : Seine-et-Marne' },
            { value: '78 : Yvelines', label: '78 : Yvelines' },
            { value: '79 : Deux-Sèvres', label: '79 : Deux-Sèvres' },
            { value: '80 : Somme', label: '80 : Somme' },
            { value: '81 : Tarn', label: '81 : Tarn' },
            { value: '82 : Tarn-et-Garonne', label: '82 : Tarn-et-Garonne' },
            { value: '83 : Var', label: '83 : Var' },
            { value: '84 : Vaucluse', label: '84 : Vaucluse' },
            { value: '85 : Vendée', label: '85 : Vendée' },
            { value: '86 : Vienne', label: '86 : Vienne' },
            { value: '87 : Haute-Vienne', label: '87 : Haute-Vienne' },
            { value: '88 : Vosges', label: '88 : Vosges' },
            { value: '89 : Yonne', label: '89 : Yonne' },
            { value: '90 : Territoire de Belfort', label: '90 : Territoire de Belfort' },
            { value: '91 : Essonne', label: '91 : Essonne' },
            { value: '92 : Hauts-de-Seine', label: '92 : Hauts-de-Seine' },
            { value: '93 : Seine-St-Denis', label: '93 : Seine-St-Denis' },
            { value: '94 : Val-de-Marne', label: '94 : Val-de-Marne' },
            { value: '95 : Val-D\'Oise', label: '95 : Val-D\'Oise' },
            { value: '971 : Guadeloupe', label: '971 : Guadeloupe' },
            { value: '972 : Martinique', label: '972 : Martinique' },
            { value: '973 : Guyane', label: '973 : Guyane' },
            { value: '974 : La Réunion', label: '974 : La Réunion' },
            { value: '976 : Mayotte', label: '976 : Mayotte' },
        ]}
      />
    );
}
