/** @jsx jsx */
import { Select, SelectProps } from 'antd';
import React, { Fragment } from 'react';

import { jsx } from '@emotion/core';
import { formListPropertyPathToKey } from '../../../utils/formListPropertyPathToKey';
import { ResponsiveSelect } from '../../input/ResponsiveSelect';
import { DisabledContext } from '../../rights/DisabledContext';
import { copyEntityFormProps, EntityFormItemContainer, ItemProps } from '../entityForm/EntityFormItemContainer';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';

/**
 * EntityFormItemContainer for select form item with fixed number of options define staticaly
 * @param props
 * @constructor
 */
export function FixSelectFormItem(props: ItemProps & Omit<SelectProps<string>, 'disabled'>) {
    return (
      <EntityFormItemContainer
        {...copyEntityFormProps(props)}
      >
        <FixSelectFormItemContentContainer
          allowClear={props.allowClear}
          showSearch={props.showSearch}
          id={(`${props.propertyName}`)?.replace(/,/g, '_')}
          className={`${formListPropertyPathToKey(props.propertyName)}`}
          data-testid={`${props.propertyName}`?.replace(/,/g, '_')}
          options={props.options?.map((o) => ({ className: `select_${props.propertyName}`?.replace(/,/g, '_'), ...o }))}
        />
      </EntityFormItemContainer>
);
}

function FixSelectFormItemContentContainer(props: {
    value?: any,
    onChange?: any,
    options?: any,
    allowClear?: boolean,
    id?: string,
    className?: string,
    showSearch?: boolean,
    'data-testid': string
}) {
    const disabled = useScopedSelector('FixSelectFormItemContentContainer', DisabledContext, (c) => c?.disabled);
    return (
      <Fragment>
        <ResponsiveSelect
          id={props.id}
          className={props.className}
          data-testid={props['data-testid']}
          allowClear={props.allowClear}
          value={props.value}
          onChange={props.onChange}
          onClear={() => props.onChange(null, { value: null })}
          disabled={disabled}
          showSearch={props.showSearch}
        >
          {props.options?.map?.(({ value, label, className }) => (
            <Select.Option
              data-testid={value}
              key={value}
              value={value}
              className={className}
            >
              {label}
            </Select.Option>
            ))}
        </ResponsiveSelect>
      </Fragment>
    );
}
