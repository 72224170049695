import { AxiosInstance } from 'axios';
import React, { useEffect } from 'react';
import { useImmer } from 'use-immer';
import { useLoading } from '../hooks/use-loading';
import { StrictLoading } from '../common/indicator/SmartLoading';

type ColdDataContextTypeContent = {
    coldData: {};
};
export type ColdDataContextType = { coldDataCtx?: ColdDataContextTypeContent };
export const ColdDataContext = React.createContext({} as ColdDataContextType);
export const ColdDataContextProvider = (props: { children: any, axios: AxiosInstance, coldData?: any }) => {
    const { doAction, loading } = useLoading();
    const [coldDataCtx, updateColdDataCtx] = useImmer<ColdDataContextTypeContent>({
        coldData: props.coldData,
    });

    useEffect(() => {
        if (!props.coldData) {
            doAction(async () => {
                const coldData = {} as any;
                for (const entity of ['programs',
                    'school-tracks',
                    'school-track-types',
                    'nationalities',
                    'countries',
                    'programs',
                    'exemption-status',
                    'csp-status',
                    'document-category',
                    'referent-job-status',
                    'program-schools',
                    'program-types',
                    'academies',
                    'bachelor-mentions',
                    'bachelor-types',
                    'bachelor-techno-series',
                    'bachelor-general-series',
                    'bachelor-specialities',
                    'companies-as',
                    'language-levels',
                    'idioms',
                    'schools',
                    'stay-abroad-reasons',
                    'nostudy-activities']) {
                    // eslint-disable-next-line no-await-in-loop
                    const res = await props.axios.get(`/public/${entity}`);
                    coldData[entity] = res?.data || [];
                }
                updateColdDataCtx((draft) => {
                    draft.coldData = coldData;
                });
            });
        }
        // optimization
        // eslint-disable-next-line
    }, []);

    return (
        <StrictLoading loading={loading} context="ColdDataContextTypeContent">
            <ColdDataContext.Provider value={{ coldDataCtx }}>
                {props?.children}
            </ColdDataContext.Provider>
        </StrictLoading>
    );
};
