/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { IAcademic, IAcademicsList, IBachelor, ISchool, ISchoolTrack, ISchoolTrackType, SUPER_SECRETARY_RIGHTS } from '@fstn/ecandidaturev2_api-interfaces';
import { FormLayout } from 'antd/lib/form/Form';
import React, {
  Fragment, HTMLAttributes, useContext, useRef, useState,
} from 'react';
import { Flex } from 'rebass';
import { useDeepCompareCallback, useDeepCompareEffect } from 'use-deep-compare';
import { useImmer } from 'use-immer';
import UUID from 'uuid-js';
import { DeepPartial } from 'redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import _ from 'lodash';
import { AcademicYearModal } from './AcademicYearModal';
import { EntityFormContainer } from '../../../common/form/entityForm/EntityFormContainer';
import { EntityFormListContainer } from '../../../common/form/entityForm/EntityFormListContainer';
import { FormContentPanelContainer } from '../../../common/form/formContentPanel/FormContentPanelContainer';
import { FormStyle } from '../../../common/form/FormStyle';
import { UploadFormItemContainer } from '../../../common/form/inputFormItems/uploadFormItem/UploadFormItemContainer';
import { SecretaryReviewFormFooterContainer } from '../../../common/form/secretaryReviewForm/SecretaryReviewFormFooterContainer';
import { WithSwitchToReadOnlyOnDisplayForSecretary } from '../../../common/withExtraParameters/WithSwitchToReadOnlyOnDisplayForSecretary';
import { WithInitialLoad } from '../../../common/withExtraParameters/WithInitialLoad';
import { YesNoFormItem } from '../../../common/form/inputFormItems/YesNoFormItems';
import { EntityFormContext } from '../../../context/entityForm.context';
import { UserContext, UserContextType } from '../../../context/user.context';
import { useEntity } from '../../../hooks/use-entity';
import { IsSecretary } from '../../user/conditionnal/IsSecretary';
import { CanSeeValidation } from '../../../common/conditionnal/validation/CanSeeValidation';
import { AcademicFormContainer } from './AcademicFormContainer';
import { AcademicReferentsReviewsListForm } from './AcademicReferentReviewsListForm';
import { DisabledContext, DisabledContextProvider } from '../../../common/rights/DisabledContext';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';
import { DisabledContextProviderForEntityForm } from '../../../common/rights/DisabledContextProviderForEntityForm';
import { FinalReview } from './FinalReview';
import { CandidateFileContext, CandidateFileContextProviderType } from '../../canSeeCandidateFile/CandidateFileContext';
import * as Locale from '../../../common/locale';
import { FormListFieldData } from '../../../common/form/type';
import { ColdDataAutoCompleteFormItem } from '../../../common/form/inputFormItems/ColdDataAutoCompleteFormItem';
import { SchoolAutoCompleteFormItem } from '../../../common/form/inputFormItems/SchoolAutoCompleteFormItem';
import { RightAreaContext, RightAreaContextProviderType } from '../../canSeeCandidateFile/RightAreaContext';
import { useConfig } from '../../../hooks/use-config.hook';
import { ARReview } from '../../../../../back/src/referent/entities/academic-referent-review.entity';

export type AcademicsListProps = HTMLAttributes<HTMLElement>;

/**
 * Genere un bloc d'affichage precisant l'année d'obtention du bac telle que specifiee par le candidat, et les consignes precisant
 * qu'il faut remplir toutes les annees de formation depuis cette date
 * @returns 
 */
function HeaderDetails(nbAcademics: number) {
  // Recuperation de l'annee courante pour calculer le nombre d'années postbac devant etre renseignees
  const currentYear = new Date().getFullYear();
  const [{ maxLimit }] = useConfig();
  const maxAcademics = maxLimit('ACADEMICS') || 6;
  // Recuperation des infos du bac liees au dossier du candidat
  const { loadEntity } = useEntity<IBachelor>();
  const [state, updateSate] = useImmer({
    loaded: false,
    entityValue: undefined as IBachelor,
  });
  async function load() {
    updateSate((draft) => {
      draft.loaded = true;
    });
    const res = await loadEntity('bachelor');
    updateSate((draft) => {
      draft.entityValue = res;
    });
  }

  useDeepCompareEffect(() => {
    if (!state.loaded) {
      load().then();
    }
  }, [state.loaded]);

  if (!state.loaded || !state.entityValue) {
    return <Fragment />;
  }
  const nbAcademicsExpected: number = currentYear - state.entityValue.obtentionYear;  // Calcul du nombre de crusus attendus depuis l'annee du bac jusqu'a date courante

  return (
    <Fragment>
      <span css={css`font-size: 1.2em; font-weight: bold; color: #DC143C`}>
        <Locale.Content tkey='bachelorObtentionDate' /> {state.entityValue.obtentionYear}
        <br />
        {/* On n'affiche pas le bloc du nombre de cursus attendu et l'eventuel warning si ce nombre n'est pas atteint pour les bacs datant de plus de MAX_ACADEMICS annees */}
        {nbAcademicsExpected <= maxAcademics && (
          <Fragment>
          <Locale.Content tkey='nbAcademicsExpected' /> {nbAcademicsExpected}
          <br />
          {/* Si le nombre d'annees attendues (en fonction de l'annee du bac) n'est pas atteint, on affiche un warning */}
          {nbAcademics < nbAcademicsExpected && (
            <Fragment>
            <Locale.Content tkey='warningMissingAcademics' />
            <br />
            </Fragment>
          )}
          </Fragment>
        )}
      
      </span>
      <Locale.Content tkey='fillRecommandation' />
    </Fragment>
  )
}

const entity = 'academics-list';
export function AcademicsListEntityFormContainer(props: { yearUpdated: number }) {
  // console.log('Redraw AcademicsListEntityFormContainer');
  const { deleteEntity } = useEntity();
  const { putEntity } = useEntity<any>();
  const hiddenYears = useScopedSelector<any[], number[]>('AcademicsListEntityFormContainer_year', EntityFormContext, (c) => c[0]?.entityValue?.academics?.map((a) => a.year));
  const academics = useScopedSelector<any[], any>('AcademicsListForm', EntityFormContext, (c) => c[0]?.entityValue?.academics);
  const lastDegreeName = useScopedSelector<any[], ISchoolTrack>('AcademicsListForm_lastDegreeName', EntityFormContext, (c) => c[0]?.entityValue?.lastDegreeName);
  const lastDegreeSchoolTrackType = useScopedSelector<any[], ISchoolTrackType>('AcademicsListForm_lastDegreeSchoolTrackType', EntityFormContext, (c) => c[0]?.entityValue?.lastDegreeSchoolTrackType);
  const lastSchoolName = useScopedSelector<any[], ISchool>('AcademicsListForm_lastSchoolName', EntityFormContext, (c) => c[0]?.entityValue?.lastSchoolName);
  const disabled = useScopedSelector('AcademicFormContainer_disabled', DisabledContext, (c) => c?.disabled);
  const jpeCompleted = useScopedSelector<any[], boolean>('AcademicsListForm', EntityFormContext, (c) => c[0]?.entityValue?.jpeCompleted);
  const jpeProvided = useScopedSelector<any[], boolean>('AcademicsListForm', EntityFormContext, (c) => c[0]?.entityValue?.jpeProvided);

  const { candidateFileContext } = useContext<CandidateFileContextProviderType>(CandidateFileContext);
  const candidateFileId = candidateFileContext?.selectedCandidateFile?.id;
  const { canEditValidation } = useContext(UserContext);

  const [state, updateState] = useImmer({
    schoolTracks: [],
    showAddAcademic: false,
    add: undefined,
    yearAdded: undefined,  // Annee du cursus que l'on vient d'ajouter (pour pouvoir ouvrir l'onglet correspondant en refresh)
    firstRender: true  // Permet de savoir si on est au premier render et donc si on doit tenir compte de l'année passée en param dans la prop (qui helas reste définie en permanence)
  });

  async function onAddAcademic(academic: any) {
    updateState((draft) => {
      draft.showAddAcademic = false;
      draft.yearAdded = academic.year;
      draft.firstRender = false;  // On indique qu'on n'est plus au premier rendu (sinon, l'appli tient compte de la props pour l'ouverture de l'onglet)
    });
    state.add(academic);
  }

  async function onCancelAddAcademic() {
    updateState((draft) => {
      draft.showAddAcademic = false;
    });
  }

  function onChangeCollapseState(item) {
    updateState((draft) => {
      draft.yearAdded = academics[item]?.year;
      draft.firstRender = false;  // On indique qu'on n'est plus au premier rendu (sinon, l'appli tient compte de la props pour l'ouverture de l'onglet)
    });
  }

  function getAcademicForField(field: FormListFieldData) {
    return academics?.[field.fieldKey];
  }

  let academicToExpand; // Contient l'annee de l'onglet des cursus a ouvrir lors des refresh
  /* Dans le cas du premier rendu (arrivée dans la page ou refresh general suite à une modification de la periode qui doit rafraichir les noms de matiere)
     on ouvre (s'il est defini) le cursus liée à l'année passée en props (cas du refresh general suite à un changement de periode)
     Sinon, on prend l'année qui a été ajoutée (ou ouverte via l'ouverture d'onglet via onChangeCollapseState)
  */
  if (state.firstRender) {
    academicToExpand = props.yearUpdated || state.yearAdded;
  }
  else {
    academicToExpand = state.yearAdded;
  }

  return (
    <Flex flexDirection="column">
      <AcademicYearModal
        onCancel={onCancelAddAcademic}
        onAddAcademic={onAddAcademic}
        showAcademicYear={state.showAddAcademic}
        hiddenYears={hiddenYears}
      />
      <FormContentPanelContainer partName={entity}>
        <CanSeeValidation>

        <DisabledContextProvider value={{
          disabled: !canEditValidation(),
          wrapAuthorized: true,
          context: 'AcademicsListForm',
        }}
        >
          <FormContentPanelContainer partName="common">
            <Fragment>
              <DisabledContextProvider value={{ disabled: disabled || !!lastDegreeName, wrapAuthorized: true }}>
                <ColdDataAutoCompleteFormItem
                  api="school-track-types"
                  allowClear
                  propertyName='lastDegreeSchoolTrackType'
                />
              </DisabledContextProvider>
              {!!lastDegreeSchoolTrackType
                && (
                  <DisabledContextProvider value={{ disabled: disabled || !!lastSchoolName, wrapAuthorized: true }}>
                    <ColdDataAutoCompleteFormItem
                      api="school-tracks"
                      allowClear
                      propertyName='lastDegreeName'
                      dependenciesValues={[lastDegreeSchoolTrackType]}
                      dependencies={['lastDegreeSchoolTrackType']}
                      filter={(s: ISchoolTrack) => {
                        return lastDegreeSchoolTrackType.id === s.type?.id;
                      }}
                    />
                  </DisabledContextProvider>
                )}
              {!!lastDegreeName && !!lastDegreeSchoolTrackType
                && (
                  <DisabledContextProvider value={{ disabled: disabled, wrapAuthorized: true }}>
                    <SchoolAutoCompleteFormItem
                      allowClear
                      schoolTrack={lastDegreeName}
                      dependenciesValues={[lastDegreeName]}
                      dependencies={['lastDegreeName']}
                      propertyName='lastSchoolName'
                    />
                  </DisabledContextProvider>
                )}
            </Fragment>
          </FormContentPanelContainer>
        </DisabledContextProvider>
        </CanSeeValidation>

        <EntityFormListContainer
          propertyName="academics"
          entity="academics"
          ignoreMaxLimit={true}  // Permet d'indiquer ici qu'on n'interdit pas de saisir plus de cursus que specifie dans MAX_ACADEMICS car on peut saisir des années supplementaires
          headerDetails={HeaderDetails(academics.length)}
          sort={(acc1, acc2) => getAcademicForField(acc2)?.year - getAcademicForField(acc1)?.year}
          hackRefresh={state.yearAdded ? [Math.random()] : [1]  // Ruse qui permet, en affectant une nouvelle valeur a cette prop, d'indiquer au composant de forcer un refresh de la liste
            // necessaire ici pour reorganiser la liste completement (tri) après chaque ajout d'un nouveau cursus
          }
          addDecorator={async (add) => {
            updateState((draft) => {
              draft.showAddAcademic = true;
              draft.add = add;
            });
          }}
          removeDecorator={async (remove, value: IAcademic) => {
            if (value.degreeDocument) {
              await deleteEntity('document', value.degreeDocument);
            }
            if ((value as any).bulletinOnLastYearDocument) {
              await deleteEntity('document', (value as any).bulletinOnLastYearDocument);
            }
            if (value.notation1Document) {
              await deleteEntity('document', value.notation1Document);
            }
            if (value.notation2Document) {
              await deleteEntity('document', value.notation2Document);
            }
            remove();
          }}
        >
          <AcademicFormContainer academicToExpand={academicToExpand} onCollapseChange={onChangeCollapseState} hiddenYears={hiddenYears}/>
        </EntityFormListContainer>

        <CanSeeValidation>

          <DisabledContextProvider value={{
            disabled: !canEditValidation(),
            wrapAuthorized: true,
            context: 'AcademicsListForm',
          }}
          >
            <Locale.Header tkey='reservedSpace' />
            <FinalReview
              propertyName="finalReview"
              onChange={async () => {
                await putEntity('comments/onSecretaryUpdateAcademicReferentFinalReview', { id: candidateFileId }, { candidateFileId });
              }}
            />
            <section id="documents">
              <ExternalDocument document="externalApprovalDocument" />
            </section>
            <section id="documents">
              <ExternalDocument document="externalOtherDocument1" />
            </section>
            <section id="documents">
              <ExternalDocument document="externalOtherDocument2" />
            </section>
          </DisabledContextProvider>
        </CanSeeValidation>
      </FormContentPanelContainer>

      <section id="documents">
        <AcademicsPostStudiesDocument />
      </section>
      {(jpeCompleted === false || jpeProvided === false) && (
        <section id="reviews">
        <AcademicReferentsReviewsListForm />
      </section>
      )}
      
    </Flex>
  );
}

export const AcademicsPostStudiesDocument: React.FunctionComponent<AcademicsListProps> = () => {
  // console.log('Redraw AcademicsPostStudiesDocument');

  const entityId = useScopedSelector<any[], string>('AcademicsPostStudiesDocument', EntityFormContext, (c) => c[0]?.entityValue?.id);
  const jpeProvided = useScopedSelector<any[], boolean>('AcademicsPostStudiesDocument', EntityFormContext, (c) => c[0]?.entityValue?.jpeProvided);
  const jpeCompleted = useScopedSelector<any[], boolean>('AcademicsPostStudiesDocument', EntityFormContext, (c) => c[0]?.entityValue?.jpeCompleted);
  const postStudiesApprovalDocument = useScopedSelector<any[], Document>('AcademicsPostStudiesDocument', EntityFormContext, (c) => c[0]?.entityValue?.postStudiesApprovalDocument);
  const academicReferentReviews = useScopedSelector<any[], ARReview[]>('AcademicsPostStudiesDocument', EntityFormContext, (c) => c[0]?.entityValue?.academicReferentReviews);
  const disabled = useScopedSelector('AcademicsPostStudiesDocument_disabled', DisabledContext, (c) => c?.disabled);

  const addPostStudiesApprovalDocument = useDeepCompareCallback(() => ({
    id: UUID.create(1).toString(),
    name: 'postStudiesApprovalDocument',
    type: 'postStudiesApprovalDocument',
    parentType: entity,
    parentId: entityId,
  }), [entityId, entity]);
  const { userCtx } = useContext<UserContextType>(UserContext);
  console.log("userctx",userCtx)
  // Pour permettre de disabled les champs selon les conditions explicitees ci-dessous
  const fieldsDisable: boolean = disabled || (academicReferentReviews?.length > 0 && 
    !(userCtx.user?.rightsOverriding && _.isEqual(userCtx.user?.rightsOverriding, SUPER_SECRETARY_RIGHTS)));

  return (
    <Fragment>
      <FormContentPanelContainer partName="postStudiesApprovalDocument">
        {/* On ne peut plus modifier ce choix de presence d'un avis JPE si une demande de reco a ete envoyee
        car si on passe a oui, elle se retrouve masquee (mais toujours presente) sauf pour les SSD */}
        <DisabledContextProvider value={{ disabled: fieldsDisable , wrapAuthorized: true }}>
          <YesNoFormItem labelCol={{ span: 12 }} wrapperCol={{ span: 10 }} propertyName="jpeProvided" />
          { jpeProvided === true && (
            <Fragment>
              <UploadFormItemContainer
                propertyName="postStudiesApprovalDocument"
                factory={addPostStudiesApprovalDocument}
                accept={'image/*,.pdf'}
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 10 }}
              />
              {postStudiesApprovalDocument && (
                <YesNoFormItem labelCol={{ span: 12 }} wrapperCol={{ span: 10 }} propertyName="jpeCompleted" />             
              )}
            </Fragment>
          )}
       </DisabledContextProvider>
      </FormContentPanelContainer>
      {jpeCompleted === false && (
        <div css={css`padding-left: 2.25em; padding-right: 2.25em`}>
          <Locale.Content tkey={'Academics-list.jpeNotCompleted'} />
        </div>
      )}
      {jpeProvided === false && (
        <div css={css`padding-left: 2.25em; padding-right: 2.25em`}>
          <Locale.Content tkey={'Academics-list.jpeNotProvided'} />
        </div>
      )}
    </Fragment>
  );
};

export function ExternalDocument(props: { document: string }) {
  const entityId = useScopedSelector<any[], string>(`AcademicsExternalDocument`, EntityFormContext, (c) => c[0]?.entityValue?.id);
  const propertyName = props.document;
  const addExternalDocument = useDeepCompareCallback(() => ({
    id: UUID.create(1).toString(),
    name: propertyName,
    type: propertyName,
    parentType: entity,
    parentId: entityId,
  }), [entityId, entity]);
  return (
    <Fragment>
      <FormContentPanelContainer partName={propertyName}>
        <UploadFormItemContainer
          propertyName={propertyName}
          factory={addExternalDocument}
          accept={'image/*,.pdf'}
        />
      </FormContentPanelContainer>
    </Fragment>
  );
};

export function AcademicsListForm(props: { layout?: FormLayout }) {
  const [forceReloadHack, setForceReloadHack] = useState(0);
  const previousData = useRef(null);
  const { canEditValidation } = useContext<UserContextType>(UserContext);
  const { deleteEntity } = useEntity();
  const rightCtx = useContext<RightAreaContextProviderType>(RightAreaContext);

  const [state, updateState] = useImmer({
    yearUpdated: undefined  // Permet de savoir si on a demande un refresh en precisant l'année concernée
  });

  const onBeforeValidate = async (values: IAcademicsList | any, differences: Partial<IAcademicsList>, setFieldsValue, oldData: DeepPartial<IAcademicsList>) => {
    // Suppression de l'avis JPE si on passe à FALSE jpeProvided et on reset jpeCompleted
    if (differences.jpeProvided === false) {
      if (values.postStudiesApprovalDocument) {
        await deleteEntity('document', values.postStudiesApprovalDocument);
        differences.postStudiesApprovalDocument = null;
        setFieldsValue({ values, postStudiesApprovalDocument: null });
        rightCtx?.forceUpdate?.();
      }
      differences.jpeCompleted = null;
      setFieldsValue({ values, jpeCompleted: null });
    }
    // S'il n'y a pas de postStudiesApprovalDocument, on reset le champ jpeCompleted (utile dans le cas ou on supprime le document et qui doit reseter le champ)
    if (!!!values.postStudiesApprovalDocument) {
      differences.jpeCompleted = null;
      setFieldsValue({ values, jpeCompleted: null });
    }
    if (differences?.academics?.length > 0) {
      // On regarde ici si des criteres d'obligation du releve de note BTS ont été supprimés, auquel cas on supprime le degreeDocument qui n'a plus lieu d'etre
      differences?.academics?.forEach(async (academicUpdate, i) => {
        // On se positionne sur le cursus modifie (s'il existe)
        if (academicUpdate) {
          if ((!!!(values.academics[i]?.degreeObtained === true) || values.academics[i]?.period !== 'seconde année') && values.academics[i]?.degreeDocument?.id) {
            await deleteEntity('document', values.academics[i]?.degreeDocument);
            _.set(differences, `academics[${i}].degreeDocument`, null);
            rightCtx?.forceUpdate?.();
            setForceReloadHack(Math.random());
          }
          // Dans le cas ou on a modifie l'annee du cursus, il faut faire un refresh de la page (pour reorganiser les onglets depliables et mettre a jour les noms des pieces du cursus)
          if (academicUpdate.year) {
            const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
            await sleep(500);
            _.set(differences, `academics[${i}].year`, academicUpdate.year);
            rightCtx?.forceUpdate?.();
            setForceReloadHack(Math.random());
          }
          // Reset des champs conditionnes : cancelReason et schoolDetail qui doivent etre vidés si les conditions de leur remplissage ne sont pas remplies
          // cancelCourse doit etre à true pour cancelReason, et school.name = "Autre établissement" pour schoolDetail
          if (values.academics[i]?.cancelCourse !== true) {
            _.set(differences, `academics[${i}].cancelReason`, null);
          }
          if (!!!values.academics[i]?.school?.name.includes('Autre établissement')) {
            _.set(differences, `academics[${i}].schoolDetail`, null);
          }
          // NOTE : le setFieldValues ne fonctionne pas sur les details d'un cursus (il ne fonctionne que sur les champs principaux de la liste)
          // donc on ne peut pas faire le reset dans l'IHM !
        }
      })
      differences?.academics?.forEach((academicUpdate, i) => {
        const previous = previousData.current || oldData;
        const academicPreviousValue = _.get(previous, `academics[${i}]`);
        if (canEditValidation()
          && academicUpdate
          && 'period' in academicUpdate
          && academicUpdate.period
          // eslint-disable-next-line eqeqeq
          && academicUpdate.period != _.get(academicPreviousValue, 'period')) {
          _.set(differences, `academics[${i}].s1GenAverage`, null);
          _.set(differences, `academics[${i}].s2GenAverage`, null);
          _.set(differences, `academics[${i}].s2m1Average`, null);
          _.set(differences, `academics[${i}].s2m2Average`, null);
          _.set(differences, `academics[${i}].s1m1Average`, null);
          _.set(differences, `academics[${i}].s1m2Average`, null);
          _.set(differences, `academics[${i}].s1Rank`, null);
          _.set(differences, `academics[${i}].s2Rank`, null);
          Modal.warning({
            title: <Locale.Notification tkey="secretaryReview.changingNote.subjectName" />,
            icon: <ExclamationCircleOutlined />,
            content: <Locale.Help tkey="secretaryReview.changingNote.subjectName" />,
            async onOk() {
              setTimeout(() => {
                const currId = _.get(previous, `academics[${i}].id`);
                updateState((draft) => {
                  draft.yearUpdated = _.get(previous, `academics[${i}].year`)
                });
                window.location.href = `${window.location.href}#${currId}`;
                localStorage.setItem('keepEdit', '1');
                setForceReloadHack(Math.random());
                setTimeout(() => {
                  window.location.href = `${window.location.href}#${currId}`;
                }, 1000);
              }, 500);
            },
          });
        }
      });
    }
    previousData.current = (values);
    return differences;
  };
  return (
    <FormStyle>
      <WithInitialLoad entity={entity} forceReloadWhenThisValueChange={`${forceReloadHack}`}>
        <WithSwitchToReadOnlyOnDisplayForSecretary>
          <EntityFormContainer listeners={{ onBeforeValidate }} layout={props.layout}>
            <DisabledContextProviderForEntityForm>
              <AcademicsListEntityFormContainer yearUpdated={state.yearUpdated} />
              <IsSecretary>
                <SecretaryReviewFormFooterContainer propertyName="secretaryReview" />
              </IsSecretary>
            </DisabledContextProviderForEntityForm>
          </EntityFormContainer>
        </WithSwitchToReadOnlyOnDisplayForSecretary>
      </WithInitialLoad>
    </FormStyle>
  );
}
